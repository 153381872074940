body,
p,
a,
ul li,
button,
div,
table tbody tr td,
table thead tr th {
  font-family: "Plus Jakarta Sans", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 800;
}

.custom-header-css {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 15px 0px;
  background: #fff;
  border-bottom: 1px solid #ddd;
}

.custom-navbar-css ul li a:hover {
  border-radius: 0 !important;
}

.nav-link.active {
  position: relative;
  background: #f0f8ff;
}

.dropdown-toggle.user-name {
  cursor: pointer;
}

.add-cutomer-section .fa-plus {
  font-size: 13px;
  top: -1px;
  position: relative;
}

.nav-link.active:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #1b6ef3;
}

.customer-table-css input[type=checkbox],
.customer-table-css input[type=radio] {
  width: 14px;
  height: 14px;
  position: relative;
  top: 2px;
  cursor: pointer;
}

.customer-table-css tr:last-child {
  border-bottom: 1px solid transparent;
}

.main-content-css {
  padding-top: 115px;
  background-color: #87CEEB;
  min-height: 100vh;
  padding-bottom: 15px;
}

.search-input:focus,
.search-icon-custom:focus {
  box-shadow: none;
  border-color: #ddd;
}

.search-icon-custom {
  background: #fff;
}

.btn {
  font-size: 15px;
}

.add-customer-btn,
.search-input,
.search-icon-custom,
#dropdownMenuButton {
  padding: 0px 11px;
  min-height: 32px;
  font-size: 14px;
  border-radius: 5px;
  background: #fff;
}

.add-customer-btn:hover,
.add-customer-btn:active {
  color: #0d6efd !important;
  background-color: #fff !important;
}

.customer-table-css .fa-plus {
  font-size: 13px;
  position: relative;
  top: -1px;
}

#dropdownMenuButton,
.add-customer-btn {
  color: #0d6efd;
  border: 1px solid #ddd !important;
}

.search-input:focus {
  border: 1px solid #3378ff;
  border-right: 0 !important;
}

.search-input:focus~.input-group-append .search-icon-custom {
  border: 1px solid #3378ff !important;
  border-left: 0 !important;
}

.search-icon-custom {
  border-radius: 0 0.375rem 0.375rem 0;
}

.customer-table-css tbody tr td {
  font-size: 12px;
}

.customerTable td {
  padding: 5px 8px 2px 8px;
}

.customerTable td>div,
.customerTable td>span {
  max-height: 35px;
  overflow: hidden;
  display: inline-block;
}

.customer-table-css thead tr th:first-child,
.customer-table-css tbody tr td:first-child {
  padding-left: 17px;
}

.customer-table-css thead tr th {
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  text-align: left !important;
  font-size: 15px;
  ;
}

.customer-pagination .page-item .page-link:focus {
  box-shadow: none;
}

.dropdown-menu li:first-child:hover .dropdown-item {
  border-radius: 0.375rem 0.375rem 0 0;
}

.dropdown-menu li:last-child:hover .dropdown-item {
  border-radius: 0 0 0.375rem 0.375rem;
}

.dropdown-menu li:last-child .dropdown-item {
  border-bottom: 0 !important;
}

.dropdown-menu li .dropdown-item {
  border-bottom: 1px solid #ddd !important;
  font-size: 14px;
  text-transform: capitalize;
  display: flex;
  gap: 8px;
}

.dropdown-menu {
  padding: 0;
}

.add-cutomer-section .dropdown-menu.addCol {
  left: -30px !important;
  top: 3px !important;
  max-height: 388px;
  overflow: hidden;
  overflow-y: scroll;
}

/* width */
.add-cutomer-section .dropdown-menu.addCol::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.add-cutomer-section .dropdown-menu.addCol::-webkit-scrollbar-track {
  background: #d4d1d1;
}

/* Handle */
.add-cutomer-section .dropdown-menu.addCol::-webkit-scrollbar-thumb {
  background: #b3b3b3;
  border-radius: 5px;
}

/* Handle on hover */
.add-cutomer-section .dropdown-menu.addCol::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.searchParentWrapper {
  display: grid;
  grid-template-columns: 2fr 0.4fr 1.3fr;
  gap: 10px;
}


.add-cutomer-section {
  border-radius: .375rem .375rem 0 0;
}

.serial-no {
  width: 45px;
  ;
}

.customer-table-css thead th {
  white-space: nowrap;
}

.sortable-header .fa-sort-down {
  position: relative;
  top: -2px;
}

.sortable-header .fa-sort-up {
  position: relative;
  top: 6px;
}

.user-menu {
  position: relative;
  cursor: pointer;
}

.user-menu .user-name {
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.user-dropdown {
  display: none;
  position: absolute;
  right: 0;
  background: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  border-radius: 4px;
  overflow: hidden;
}

.user-menu:hover .user-dropdown {
  display: block;
}

.user-dropdown .dropdown-item {
  display: block;
  width: 100%;
  padding: 10px;
  border: none;
  background: none;
  text-align: left;
  cursor: pointer;
  font-size: 16px;
}

.user-dropdown .dropdown-item:hover {
  background-color: #ddd;
}

.navbar-nav .nav-link {
  color: black;
  font-size: 14px;
  font-weight: 500;
}

.navbar-nav .nav-link:hover {
  background-color: #ddd;
  border-radius: 4px;
}

.customer-table-css th {
  position: relative;
  background: white;
}

.add-cutomer-section .fa-user {
  background: #ff8f66;
  padding: 6px 8px;
  border-radius: 5px;
  color: #fff;
}

.react-resizable {
  position: initial !important
}

.react-resizable-handle-e {
  right: 0;
  transform: rotate(0) !important;
  display: block;
  height: 47px !important;
  width: max-content !important;
}

.react-resizable-handle-e {
  margin-top: -23px !important;
}

.sortable-header {
  cursor: pointer;
  position: absolute;
  right: 12px;
}

.no-of-item {
  font-weight: 500;
  color: #858383;
}

/* .truncate-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 150px; 
} */

.leadWrapper {
  width: 235px;
  float: right;
  align-items: center;
  display: flex;
}

.leadWrapper .label-title {
  width: 160px;
}

.customer-details-grid {
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 15px;
}

.first-column-box,
.second-column-box,
.third-column-box {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
}

.detail-tab-box {
  border-bottom: 1px solid #ddd;
  margin-bottom: 15px;
}

.detail-tab-box h5 {
  width: max-content;
  margin-bottom: 0;
  border-bottom: 3px solid blue;
  padding-bottom: 10px;
}

.label-title {
  font-size: 14px;
  color: grey;
  font-weight: 400;
  padding-bottom: 5px;
}

.label-value {
  width: 100% !important;
}

.comment-text {
  font-size: 14px;
  line-height: normal;
  color: #000000;
}

.comment-timestamp {
  font-size: 12px;
  color: grey;
}

.comment-box {
  max-height: 300px;
  overflow: auto;
}

.fa-arrow-left {
  cursor: pointer;
}

.status-card-list ul {
  list-style-type: none;
  padding-left: 0;
  display: flex;
  border: 1px solid #ddd;
  border-radius: 25px;
  margin-bottom: 0;
}

.status-card-list ul li {
  border-right: 1px solid #ddd;
  padding: 5px 30px;
  position: relative;
}

.status-card-list ul li:last-child {
  border-right: 0;
}

input:focus,
textarea:focus,
select:focus {
  box-shadow: none !important;
}

select {
  height: 38px;
}

select,
textarea,
input {
  font-size: 15px !important;
  appearance: auto !important;
}

.custom-header-css .dropdown-menu.show li a {
  text-decoration: none;
}

.dropdown-menu li .dropdown-item {
  background: none;
  color: #000000 !important;
}

.dropdown-menu li:hover .dropdown-item {
  background: none;
  font-weight: 600;
}

.login-box-width {
  width: 350px;
  max-width: 100%;
}




.notification-popup {
  position: fixed;
  top: 5px;
  font-size: 14px;
  right: 10px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  visibility: visible;
  opacity: 1;
  background-color: var(--bg-color, #ffffff);
  color: var(--text-color, #000000);
}

.progress-bar {
  height: 4px;
  width: 0%;
  background-color: #007bff;
  margin-top: 5px;
}



.close-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: inherit;
}